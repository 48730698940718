import React, { Fragment, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as apiServices from '../../../services/apiServices';

export default function FileUploadPage() {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [apiResponse, setApiResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Handle file selection
    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(files);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            toast.error("Please select files to upload");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        
        // Append all selected files to formData
        selectedFiles.forEach((file) => {
            formData.append('json_files', file);
        });

        try {
            const response = await apiServices.uploadMembers(formData);
            setApiResponse(JSON.stringify(response.data, null, 2));
            toast.success("Files uploaded for processing");
        } catch (error) {
            setApiResponse(JSON.stringify(error.response?.data || error.message, null, 2));
            toast.error("Upload failed!");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <PageTitle activeMenu="Upload Members" motherMenu="Members" pageContent="Upload Members" />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="row">
                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <Card.Title>Upload Member Files</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="basic-form">
                                <div className="form-group mb-3">
                                    <label className="form-label">Select files</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        multiple
                                        accept=".json"
                                        onChange={handleFileSelect}
                                        disabled={isLoading}
                                    />
                                    <small className="text-muted">
                                        Selected files: {selectedFiles.map(f => f.name).join(', ')}
                                    </small>
                                </div>

                                <button
                                    className="btn btn-primary"
                                    onClick={handleUpload}
                                    disabled={isLoading || selectedFiles.length === 0}
                                >
                                    {isLoading ? 'Uploading...' : 'Upload'}
                                </button>

                                {apiResponse && (
                                    <div className="form-group mt-4">
                                        <label className="form-label">File(s) upload status</label>
                                        <textarea
                                            className="form-control"
                                            rows="15"
                                            value={apiResponse}
                                            readOnly
                                            style={{
                                                fontFamily: '"Inter", sans-serif',
                                                minHeight: '250px',
                                                resize: 'vertical',  // Allows vertical resizing
                                                whiteSpace: 'pre-wrap',  // Preserves formatting
                                                overflowY: 'auto'  // Adds scrollbar when needed
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
}