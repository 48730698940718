import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import data from "../../components/table/tableData.js";
import '../Application/Fileupload.css'
import {
    Tab,
    Nav,
    Pagination,
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BasicDatatable from '../../components/table/BasicDatatable'
import { get, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as apiServices from '../../../services/apiServices'
import Loader from "../../layouts/nav/Loader.js";
import CommonPrint from "../Application/CommonPrint.js";
import SecondPagePrint from "../Application/SecondPagePrint.js";
import FileUpload from "../Application/FileUpload.js";


const schema = yup.object({
    funding_source: yup.string().nullable(),
    member_name: yup.string(),
    unz_id: yup.string().nullable(),
    projected_cost: yup.string().nullable(),
    site_id: yup.string().nullable(),
    dob: yup.string().required('Date of birth is required'),
    ethnicity: yup.mixed().nullable(),
    address: yup.string().nullable(),
    city: yup.string().nullable(),
    AL: yup.string().nullable(),
    home_phone: yup.string().matches(/^[0-9 \-]{12}$/, 'Home Phone must be 10 digits. Format 000-000-0000').required('Home Phone is required'),
    mobile_phone: yup.string().nullable(),
    property_size: yup.string().nullable(),
    unicorporated_area: yup.mixed(),
    residence_type: yup.mixed(),
    water_source: yup.mixed(),
    no_of_occupants: yup.string().nullable(),
    no_of_bedrooms: yup.string().nullable(),
    sewage_disp_meth: yup.mixed(),
    dl_proof: yup.mixed(),
    signed_form: yup.mixed(),
    // .test('fileSize', 'File size is too large', (value) => {
    //     if (value) return true; // Allow empty file
    //     return value.size <= 1024 * 1024; // 1 MB
    //   }).test('fileType', 'Invalid file type', (value) => {
    //     if (!value) return true; // Allow empty file
    //     return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    //   }), 
    land_own_proof: yup.mixed(),
    water_usage_proof: yup.mixed(),
    income_proof: yup.mixed(),
    latitude: yup.string().nullable(),
    longitude: yup.string().nullable(),
    state: yup.string().nullable(),
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required')
});


export default function Users() {
    const [memberName, setMemberName] = useState("");
    const [Sponsor, setSponsor] = useState("ARPA");
    const [fileUpload1checkbox, setfileUpload1checkbox] = useState(false);
    const [fileUpload2checkbox, setfileUpload2checkbox] = useState(false);
    const [fileUpload3checkbox, setfileUpload3checkbox] = useState(false);
    const [fileUpload4checkbox, setfileUpload4checkbox] = useState(false);
    const [btntitle, setbtntitle] = useState("Add Member");
    const [cardtitle, setcardtitle] = useState("List of Members");
    const [Showform, setShowform] = useState(false);
    const [usertable, setusertable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [memberId, setMemberId] = useState("");
    const [activeTab, setactiveTab] = useState("Pending");
    const [isLoading, setIsLoading] = useState(false);
    const [submittedValues, setSubmittedValues] = React.useState(null);
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);

    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        // defaultValues: {
        //     member_id: '', 
        //     project_name: '', 
        //     last_paid_amount: '', 
        //     current_due_amount: '', 
        //     over_due_amount: '', 
        //     last_paid_date: '', 
        //     current_due_date: '', 
        //     over_due_date: '', 
        //     payment_method_others: '', 
        //   }
    });

    useEffect(() => {
        const firstName = getValues("first_name") || "";
        const lastName = getValues("last_name") || "";
        const fullName = `${firstName} ${lastName}`.trim();
        setValue("member_name", fullName);
        setMemberName(fullName);
    }, [getValues("first_name"), getValues("last_name")]);

    const { register: registerForm1, handleSubmit: handleSubmitForm1, formState: { errors1 }, reset: reset1, setValue: setValue1, getValues: getValues1 } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setbtntitle("Add Member");
        setcardtitle("List of Members")
        setactiveuser({})
        setShowform(false)
        getMembers(activeTab)
    }, [activeTab])

    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.dob.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.home_phone.toLowerCase().includes(searchInput.toLowerCase()) 
        );
    };

    const calculatePaginationDetails = (page, data, currPage) => {
        if(typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.dob.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.home_phone.toLowerCase().includes(searchInput.toLowerCase()) 
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if(page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
           currentPage = currentPage - 1;
           if(currentPage < 1) {
              currentPage = 1
           }
        } else if(page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
           currentPage = currentPage + 1;
           if(currentPage > pages) {
              currentPage = pages;
           }
        } else if(page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
           currentPage = 1
        } else if(page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
           currentPage = pages;
        } else {
           currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for(let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
           if(i <= pages)
           pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize) ;
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for(let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
           usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)
        let updatedState = {
           usersToBeShown: usersToBeShown,
           pageArray: pageArray,
           firstPage: firstPage,
           lastPage: lastPage,
           currentPage: currentPage,
           sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize))+parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
     }

     const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
           calculatePaginationDetails(txt !== undefined ? txt : e.target.text, usertable, currentPage);
        // }
     }

     const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
        // calculatePaginationDetails(1, usertable, currentPage,e)
        setTimeout(() => {
            if(document.querySelector(".page-link"))
            document.querySelector(".page-link").click()
        }, 1000);
     }

     useEffect(() => {
        calculatePaginationDetails(1, usertable, currentPage)
     }, [searchInput]);

    const onSubmit = (e) => {
        //e.preventDefault()
        // Attachments are not mandatory
        if (cardtitle === "Add Member") {
            // if (getValues().dl_proof.length == 0 || getValues().income_proof.length == 0 || getValues().land_own_proof.length == 0 || getValues().water_usage_proof.length == 0) {
            //     notifyDError("Upload Files");
            //     return false;
            // }
            apiServices.addMember(getValues())
      .then(response => {
        console.log('API Response for Member ADD:', response); // Debug log
        if (response.data && response.data[0] && response.data[0].message) {
          toast.success(response.data[0].message); // Use toast.success directly
        } else {
          toast.success("Member data created successfully"); // Fallback message
        }
        reset();
        // cardbtn();
      })
      .catch(error => {
        toast.error(error.response?.data?.message || "Error adding member"); // Handle error case
      });
  } else {
    setValue('signed_form', selectedFiles);
    apiServices.updateMember(getValues(), memberId)
      .then(response => {
        console.log('API Response for Member UPDATE:', response); // Debug log
        toast.success("Member updated successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        // if (response.data && response.data[0] && response.data[0].message) {
        //   toast.success(response.data[0].message); // Use toast.success directly
        // } else {
        //   toast.success("Member data updated successfully"); // Fallback message
        // }
        // reset();
        // cardbtn();
      })
      .catch(error => {
        toast.error(error.response?.data?.message || "Error updating member"); // Handle error case
      });
  }
};

    const updateMemberWFStatus = (sType) => {
        apiServices.updateMemberWFStatus(sType, memberId).then(response => {
            notifySuccess(response.data[0].message);
            reset();
            // cardbtn()
            // getMembers(activeTab);
        }).catch(error => {
        });

    };
    const getMembers = (sType) => {
        setIsLoading(true);
        apiServices.getMemberList(sType).then(response => {
            setusertable(response.data)
            calculatePaginationDetails(1, response.data, currentPage)
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }

    const downloadFormValuesAsJson = () => {
        const formValues = getValues();
        const jsonString = JSON.stringify(formValues, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        
        // Create filename using first_name and last_name
        const firstName = formValues.first_name || 'firstname';
        const lastName = formValues.last_name || 'lastname';
        const fileName = `${firstName}_${lastName}_member_details.json`;
        
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const resetForm = () => {
        reset();
        setMemberName("");
    };

    function checkboxFun(option) {
        console.log(option)
    }


    function cardbtn() {
        if (btntitle === 'Add Member' || btntitle === 'Edit Member' || btntitle === 'View Member') {
            setbtntitle("Back");
            setcardtitle("Add Member")
            setactiveuser({})
            setShowform(true)
            resetForm();
            reset1();
        } else {
            setbtntitle("Add Member");
            setcardtitle("List of Members")
            setactiveuser({})
            setShowform(false)
            getMembers(activeTab)
            resetForm();
            reset1();
        }
    }

    function viewuser(e, user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View Member")
        setactiveuser(user)
        setShowform(true)
        setSubmittedValues(user);
        setSelectedFiles([])
        setValue('funding_source', user.funding_source)
        setValue('member_name', user.member_name)
        setValue('unz_id', user.unz_id)
        setValue('projected_cost', user.projected_cost)
        setValue('site_id', user.site_id)
        setValue('dob', user.dob)
        setValue('ethnicity', user.ethnicity)
        setValue('address', user.address)
        setValue('city', user.city)
        setValue('AL', user.AL)
        setValue('home_phone', user.home_phone)
        setValue('mobile_phone', user.mobile_phone)
        setValue('property_size', user.property_size)
        setValue('unicorporated_area', user.unicorporated_area === true ? "True" : "False")
        setValue('residence_type', user.residence_type)
        setValue('water_source', user.water_source)
        setValue('no_of_occupants', user.no_of_occupants)
        setValue('no_of_bedrooms', user.no_of_bedrooms)
        setValue('sewage_disp_meth', user.sewage_disp_meth)
        setValue('dl_proof', user.dl_proof)
        setValue('land_own_proof', user.land_own_proof)
        setValue('water_usage_proof', user.water_usage_proof)
        setValue('income_proof', user.income_proof)
        setValue('latitude', user.latitude)
        setValue('longitude', user.longitude)
        setValue('first_name', user.first_name)
        setValue('last_name', user.last_name)
        setValue('state', user.state)

        setValue1('funding_source', user.funding_source)
        setValue1('member_name', user.member_name)
        setValue1('unz_id', user.unz_id)
        setValue1('projected_cost', user.projected_cost)
        setValue1('site_id', user.site_id)
        setValue1('dob', user.dob)
        setValue1('ethnicity', user.ethnicity)
        setValue1('address', user.address)
        setValue1('city', user.city)
        setValue1('AL', user.AL)
        setValue1('home_phone', user.home_phone)
        setValue1('mobile_phone', user.mobile_phone)
        setValue1('property_size', user.property_size)
        setValue1('unicorporated_area', user.unicorporated_area === true ? "True" : "False")
        setValue1('residence_type', user.residence_type)
        setValue1('water_source', user.water_source)
        setValue1('no_of_occupants', user.no_of_occupants)
        setValue1('no_of_bedrooms', user.no_of_bedrooms)
        setValue1('sewage_disp_meth', user.sewage_disp_meth)
        setValue1('dl_proof', user.dl_proof)
        setValue1('land_own_proof', user.land_own_proof)
        setValue1('water_usage_proof', user.water_usage_proof)
        setValue1('income_proof', user.income_proof)
        setValue1('latitude', user.latitude)
        setValue1('longitude', user.longitude)
        setValue1('first_name', user.first_name)
        setValue1('last_name', user.last_name)
        setValue1('state', user.state)
        setValue('signed_form', user.signed_form)
        setValue1('signed_form', user.signed_form)

    }
    function GetFileName(file_id) {
        const parts = file_id.split('/');
        return parts[parts.length - 1];
    }
    function downloadDocument(e, file_id) {
        e.preventDefault()
        apiServices.DownloadDocument(file_id).then(response => {
            const parts = file_id.split('/');

            const lastPart = parts[parts.length - 1];
            //const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = lastPart// encodeURIComponent(lastPart); // Encode the file name
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Clean up the URL

        }).catch(error => {

        })
    }

    function edituser(e, user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Edit User")
        setactiveuser(user)
        setShowform(true)
        setMemberId(user.id)
        setSubmittedValues(user);
        setSelectedFiles([])
        setValue('funding_source', user.funding_source)
        setValue('member_name', user.member_name)
        setValue('unz_id', user.unz_id)
        setValue('projected_cost', user.projected_cost)
        setValue('site_id', user.site_id)
        setValue('dob', user.dob)
        setValue('ethnicity', user.ethnicity)
        setValue('address', user.address)
        setValue('city', user.city)
        setValue('AL', user.AL)
        setValue('home_phone', user.home_phone)
        setValue('mobile_phone', user.mobile_phone)
        setValue('property_size', user.property_size)
        setValue('unicorporated_area', user.unicorporated_area === true ? "True" : "False")
        setValue('residence_type', user.residence_type)
        setValue('water_source', user.water_source)
        setValue('no_of_occupants', user.no_of_occupants)
        setValue('no_of_bedrooms', user.no_of_bedrooms)
        setValue('sewage_disp_meth', user.sewage_disp_meth)
        setValue('dl_proof', user.dl_proof)
        setValue('land_own_proof', user.land_own_proof)
        setValue('water_usage_proof', user.water_usage_proof)
        setValue('income_proof', user.income_proof)
        setValue('latitude', user.latitude)
        setValue('longitude', user.longitude)
        setValue('first_name', user.first_name)
        setValue('last_name', user.last_name)
        setValue('state', user.state)
        setValue('signed_form', user.signed_form)

    }
    function Print(elem) {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write(document.getElementById('printableMemberdiv').innerHTML);

        mywindow.document.close();
        mywindow.focus();

        mywindow.print();
        mywindow.close();

    }
    function deleteuser(e, user) {
        e.preventDefault();

        setactiveuser(user)
        apiServices.deleteMemberTrack(user.id)
        .then(response => {
            notifyError();
            // Delay the table refresh by 1-2 seconds
            setTimeout(() => {
                getMembers(activeTab);
            }, 1500); // 1.5 seconds delay
        })
        .catch(error => {
            // Handle error
        });
}

    const notifyError = () => {
        toast.error("Member Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifyDError = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('drag-over');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('drag-over');
    };

    const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
    
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
        setSelectedFiles(files);
        // Update form value if using react-hook-form
        setValue('signed_form', files[0]);
        }
    };

    const handleFileInputChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
        setSelectedFiles(files);
        setValue('signed_form', files[0]);
        }   

    };
    return (
        <Fragment>
            {isLoading ?
                <Loader />
                :
                <>
                    <PageTItle activeMenu="Add Member" motherMenu="Members" pageContent="Add Member" />
                    <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          style={{ zIndex: 9999 }}
                    />
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="col-xl-6 col-lg-6">
                                    <h4 className="card-title">{cardtitle}</h4>
                                </div>
                                <div className="col-xl-6 col-lg-6 text-end">
                                    <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                        {btntitle}
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div style={{ display: 'none' }} id="printableMemberdiv">
                                        <div className="basic-form">
                                            <CommonPrint></CommonPrint>

                                            <div style={{ border: '1px solid #dee2e6' }}>
                                                <p>Name: &nbsp; &nbsp; {activeuser.member_name} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Date: &nbsp;&nbsp;</p>
                                                <p>Ethnicity  (Circle) &nbsp; {activeuser.ethnicity}</p>
                                                <p>Address: &nbsp;<span style={{ textDecoration: 'underline' }}>{activeuser.address}</span> &nbsp;City: &nbsp; &nbsp;<span style={{ textDecoration: 'underline' }}>{activeuser.city}</span> &nbsp; &nbsp;AL&nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>{activeuser.AL}</span></p>
                                                <p>PHONE: &nbsp; &nbsp;Home <span style={{ textDecoration: 'underline' }}>{activeuser.home_phone}</span> &nbsp; &nbsp; Cellular  <span style={{ textDecoration: 'underline' }}>{activeuser.mobile_phone}</span></p>
                                            </div>

                                            <div style={{ border: '1px solid #dee2e6' }}>
                                                <p>Do you live in an unincorporated area in Lowndes County? &nbsp; &nbsp; &nbsp; {activeuser.unicorporated_area == true ? <span>Yes</span> : <span>No</span>} &nbsp; &nbsp; &nbsp;</p>
                                                <p>Type of residence? &nbsp;{activeuser.residence_type}
                                                    &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Property Size <span style={{ textDecoration: 'underline' }}>{activeuser.property_size}</span> Acres &nbsp; &nbsp;&nbsp;</p>
                                                <p>Water Source &nbsp; &nbsp; {activeuser.water_source} &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; # of Occupants &nbsp;<span style={{ textDecoration: 'underline' }}>{activeuser.no_of_occupants}</span> &nbsp; &nbsp;# of Bedrooms <span style={{ textDecoration: 'underline' }}>{activeuser.no_of_bedrooms}</span></p>
                                            </div>
                                            <div style={{ border: '1px solid #dee2e6' }}>
                                                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>What is your current method of sewage disposal?</strong></p>
                                                <p style={{ border: '1px solid #dee2e6' }}>&nbsp; &nbsp; {activeuser.sewage_disp_meth}</p>
                                            </div>
                                            <div style={{ border: '1px solid #dee2e6' }}>
                                                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<input
                                                    type="checkbox"
                                                    checked={activeuser.dl_proof != '' && activeuser.dl_proof != 'x' ? true : false}
                                                />  &nbsp; Copy of Driver&rsquo;s License/Non-Driver ID &nbsp; &nbsp; &nbsp; <input
                                                        type="checkbox"
                                                        checked={activeuser.water_usage_proof != '' && activeuser.water_usage_proof != 'x' ? true : false}
                                                    /> &nbsp;Copy of Water Usage</p>
                                                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <input
                                                    type="checkbox"
                                                    checked={activeuser.land_own_proof != '' && activeuser.land_own_proof != 'x' ? true : false}
                                                /> &nbsp; Proof of Land Ownership &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<input
                                                        type="checkbox"
                                                        checked={activeuser.income_proof != '' && activeuser.income_proof != 'x' ? true : false}
                                                    /> &nbsp; Proof of Income (example: Tax Return/SSI)</p>
                                                <p>(Name on Documents must be the same as Applicant&rsquo;s Name.)</p>
                                            </div>
                                            <div style={{ border: '1px solid #dee2e6' }}>
                                                <p>By submitting this signed application, I am requesting the BBUWP to accept me as a customer to receive an approved onsite sewage disposal system. &nbsp;I agree to pay $20 per month, this fee provides for maintenance and monitoring for the life of the system. &nbsp;I understand that the obligation to pay the $20.00 monthly fee survives any sale or transfer of the property, and that payment of the monthly fee will become the responsibility of the new owner. I understand that false or misleading information on my application may result in my being denied participation in this program. &nbsp; I understand that the BBUWP will not try and seize my home or land if the $20 per month is not received but that other collection methods will be pursued. &nbsp;I understand that employees from the Lowndes County Health Department, and their agents, have the right to enter onto my property to inspect the system for monitoring purposes or to assess if repairs are needed, for the perpetual life of the onsite sewage disposal system. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                                                <p>Signature: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Date:</p>

                                            </div>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <SecondPagePrint></SecondPagePrint>


                                        </div>
                                    </div>
                                </div>
                                <div className="default-tab">
                                    <Tab.Container defaultActiveKey="Pending" activeKey={activeTab}>
                                        <Nav as="ul" className="nav-tabs">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="Pending" onClick={() => { getMembers('Pending'); setactiveTab('Pending'); SetSearchInput("") }}>
                                                    <i className={`flaticon-381-windows me-2`} />
                                                    Pending
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="Approved" onClick={() => { getMembers('Approved'); setactiveTab('Approved'); SetSearchInput("")}}>
                                                    <i className={`flaticon-381-windows me-2`} />
                                                    Approved
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="Rejected" onClick={() => { getMembers('Rejected'); setactiveTab('Rejected'); SetSearchInput("")}}>
                                                    <i className={`flaticon-381-windows me-2`} />
                                                    Rejected
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="pt-4">
                                            <Tab.Pane eventKey="Pending">
                                                {Showform === true
                                                    ?
                                                    <div className="basic-form">
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-3">
                                                                    <label>Sponsor</label>
                                                                    <select
                                                                        {...register("funding_source")}
                                                                        defaultValue={"option"}
                                                                        id="SponsorinputState"
                                                                        className="form-control"
                                                                        value={activeuser?.['Sponsor'] || Sponsor}
                                                                        onChange={(e) => setSponsor(e.target.value)}
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                    >
                                                                        <option value="ARPA">ARPA</option>
                                                                        <option value="USDA">USDA</option>
                                                                    </select>
                                                                    {errors.funding_source && <small id="emailHelp" class="text-danger">{errors.funding_source?.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="form-group mb-3 col-md-4">
                                                                    <label>First Name</label>
                                                                    <input
                                                                        {...register("first_name")} // Changed from last_name to first_name
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="First Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        onChange={(e) => {
                                                                            setValue("first_name", e.target.value);
                                                                            const lastName = getValues("last_name") || "";
                                                                            const fullName = `${e.target.value} ${lastName}`.trim();
                                                                            setValue("member_name", fullName);
                                                                            setMemberName(fullName);
                                                                        }}
                                                                    />
                                                                    {errors.first_name && <small id="emailHelp" className="text-danger">{errors.first_name?.message}</small>}
                                                                </div>

                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Last Name</label>
                                                                    <input
                                                                        {...register("last_name")} // Changed from first_name to last_name
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Last Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        onChange={(e) => {
                                                                            setValue("last_name", e.target.value);
                                                                            const firstName = getValues("first_name") || "";
                                                                            const fullName = `${firstName} ${e.target.value}`.trim();
                                                                            setValue("member_name", fullName);
                                                                            setMemberName(fullName);
                                                                        }}
                                                                    />
                                                                    {errors.last_name && <small id="emailHelp" className="text-danger">{errors.last_name?.message}</small>}
                                                                </div>

                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Full Name</label>
                                                                    <input
                                                                        {...register("member_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={true}
                                                                        value={memberName}
                                                                    />
                                                                    {errors.member_name && <small id="emailHelp" className="text-danger">{errors.member_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Date of Birth</label>
                                                                    <input
                                                                        {...register("dob")}
                                                                        type="date"
                                                                        className="form-control"
                                                                        placeholder="Date"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['DATE']}
                                                                    />
                                                                    {errors.dob && <small id="emailHelp" class="text-danger">{errors.dob?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Ethnicity</label>
                                                                    <select
                                                                        {...register("ethnicity")}
                                                                        defaultValue={"option"}
                                                                        id="EthnicityinputState"
                                                                        className="form-control"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ETHINICITY']}
                                                                    >
                                                                        <option value="option" disabled>
                                                                            Choose Ethnicity...
                                                                        </option>
                                                                        <option value="Black">Black</option>
                                                                        <option value="Caucasian">Caucasian</option>
                                                                        <option value="African American">African American</option>
                                                                        <option value="Native American">Native American</option>
                                                                        <option value="Asian">Asian</option>
                                                                        <option value="Alaskan Native">Alaskan Native</option>
                                                                        <option value="Hawaiian">Hawaiian</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                    {errors.ethnicity && <small id="emailHelp" class="text-danger">{errors.ethnicity?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Address</label>
                                                                    <input
                                                                        {...register("address")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Address"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ADDRESS']}
                                                                    />
                                                                    {errors.address && <small id="emailHelp" class="text-danger">{errors.address?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>City</label>
                                                                    <input
                                                                        {...register("city")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="City"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['CITY']}
                                                                    />
                                                                    {errors.city && <small id="emailHelp" class="text-danger">{errors.city?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>State</label>
                                                                    <input
                                                                        {...register("state")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="State"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['STATE']}
                                                                    />
                                                                    {errors.state && <small id="emailHelp" class="text-danger">{errors.state?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>UNZ</label>
                                                                    <input
                                                                        {...register("unz_id")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="UNZ"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['UNZ ID']}
                                                                    />
                                                                    {errors.unz_id && <small id="emailHelp" class="text-danger">{errors.unz_id?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Cost</label>
                                                                    <input
                                                                        {...register("projected_cost")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        defaultValue="20"
                                                                        placeholder="Cost"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PROJECTED COST']}
                                                                    />
                                                                    {errors.projected_cost && <small id="emailHelp" class="text-danger">{errors.projected_cost?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Site Id</label>
                                                                    <input
                                                                        {...register("site_id")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Site Id"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['SITE ID']}
                                                                    />
                                                                    {errors.site_id && <small id="emailHelp" class="text-danger">{errors.site_id?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Pin code</label>
                                                                    <input
                                                                        {...register("AL")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Zipcode"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ZIP CODE']}
                                                                    />
                                                                    {errors.AL && <small id="emailHelp" class="text-danger">{errors.AL?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Home Phone</label>
                                                                    <input
                                                                        {...register("home_phone")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Home"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PHONE - HOME']}
                                                                    />
                                                                    {errors.home_phone && <small id="emailHelp" class="text-danger">{errors.home_phone?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Mobile Phone</label>
                                                                    <input
                                                                        {...register("mobile_phone")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Cellular"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PHONE - CELLULAR']}
                                                                    />
                                                                    {errors.mobile_phone && <small id="emailHelp" class="text-danger">{errors.mobile_phone?.message}</small>}
                                                                </div>
                                                                <hr></hr>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-4">
                                                                        <label>Property Size</label>
                                                                        <input
                                                                            {...register("property_size")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Property Size"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['PROPERTY SIZE']}
                                                                        />
                                                                        {errors.property_size && <small id="emailHelp" class="text-danger">{errors.property_size?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label>Latitude  </label>
                                                                        <input
                                                                            {...register("latitude")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            defaultValue="0"
                                                                            placeholder="Latitude"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['LATIDUDE']}
                                                                        />
                                                                        {errors.latitude && <small id="emailHelp" class="text-danger">{errors.latitude?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label>Longitude </label>
                                                                        <input
                                                                            {...register("longitude")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            defaultValue="0"
                                                                            placeholder="Longitude"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['LONGITUDE']}
                                                                        />
                                                                        {errors.longitude && <small id="emailHelp" class="text-danger">{errors.longitude?.message}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-6" style={{ display: "flex" }}>
                                                                        <label>Do you live in an unincorporated area in Lowndes County?</label>
                                                                        <div className="btn-group makebtnleft pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...register("unicorporated_area")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox1"
                                                                                    value={"True"}
                                                                                    // onClick={() => checkboxFun("yes")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['UNICORP'] === "YES" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Yes</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...register("unicorporated_area")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox2"
                                                                                    value={"False"}
                                                                                    // onClick={() => checkboxFun("no")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['UNICORP'] === "NO" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >No</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.unicorporated_area && <small id="emailHelp" class="text-danger">{errors.unicorporated_area?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-6">
                                                                        <label>Type of residence</label>
                                                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...register("residence_type")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox3"
                                                                                    value={"House"}
                                                                                    // onClick={() => checkboxFun("House")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['TYPE OF RESIDENCE'] === "House" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox3"
                                                                                >House</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...register("residence_type")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox4"
                                                                                    value={"Mobile home"}
                                                                                    // onClick={() => checkboxFun("Mobile Home")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['TYPE OF RESIDENCE'] === "Mobile home" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox4"
                                                                                >Mobile Home</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.residence_type && <small id="emailHelp" class="text-danger">{errors.residence_type?.message}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-6">
                                                                        <label>Water Source</label>
                                                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...register("water_source")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox5"
                                                                                    value={"Public"}
                                                                                    // onClick={() => checkboxFun("Public")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['WATER SOURCE'] === "Public" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox5"
                                                                                >Public</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...register("water_source")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox6"
                                                                                    value={"Private"}
                                                                                    // onClick={() => checkboxFun("Private")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['WATER SOURCE'] === "Private" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox6"
                                                                                >Private (Well)</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.water_source && <small id="emailHelp" class="text-danger">{errors.water_source?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label># of Occupants  </label>
                                                                        <input
                                                                            {...register("no_of_occupants")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="# of Occupants  "
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['OCCUPANTS']}
                                                                        />
                                                                        {errors.no_of_occupants && <small id="emailHelp" class="text-danger">{errors.no_of_occupants?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label># of Bedrooms </label>
                                                                        <input
                                                                            {...register("no_of_bedrooms")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="# of Bedrooms "
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['BEDROOMS']}
                                                                        />
                                                                    </div>
                                                                    {errors.no_of_bedrooms && <small id="emailHelp" class="text-danger">{errors.no_of_bedrooms?.message}</small>}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>What is your current method of sewage disposal?</label>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...register("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox10"
                                                                                value={"Straight pipe or septic system failure affecting a neighboring property"}
                                                                                // onClick={() => checkboxFun("yes")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "1 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox10"
                                                                            >Straight pipe or septic system failure affecting a neighboring property.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...register("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox12"
                                                                                value={"Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc)"}
                                                                                // onClick={() => checkboxFun("no")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "2 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox12"
                                                                            >Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc.)</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...register("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox13"
                                                                                value={"Straight pipe or septic system failure confined to my property or inside of home"}
                                                                                // onClick={() => checkboxFun("no")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "3 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox13"
                                                                            >Straight pipe or septic system failure confined to my property or inside of home.</label>
                                                                        </div>
                                                                    </div>
                                                                    {errors.sewage_disp_meth && <small id="emailHelp" class="text-danger">{errors.sewage_disp_meth?.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Documents Needed</label>
                                                                    <div className="btn-group pt-1 col-md-12 d-flex">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    style={{ display: 'none' }}
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id="checkbox1"
                                                                                    onClick={() => setfileUpload1checkbox(!fileUpload1checkbox)}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Copy of Driver’s License/Non-Driver ID</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input  {...register("dl_proof")} type="file" disabled={cardtitle === "View Member" ? true : false} />
                                                                            </div>
                                                                        </div>
                                                                        {activeuser.dl_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.dl_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.dl_proof)}</a>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    style={{ display: 'none' }}
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id="checkbox2"
                                                                                    onClick={() => setfileUpload2checkbox(!fileUpload2checkbox)}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Proof of Land Ownership</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input {...register("land_own_proof")} type="file" disabled={cardtitle === "View Member" ? true : false} />
                                                                            </div>
                                                                        </div>
                                                                        {activeuser.land_own_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.land_own_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.land_own_proof)}</a>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    style={{ display: 'none' }}
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id="checkbox2"
                                                                                    onClick={() => setfileUpload3checkbox(!fileUpload3checkbox)}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Copy of Water Usage </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input  {...register("water_usage_proof")} type="file" disabled={cardtitle === "View Member" ? true : false} />
                                                                            </div>
                                                                        </div>
                                                                        {activeuser.water_usage_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.water_usage_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.water_usage_proof)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    style={{ display: 'none' }}
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id="checkbox2"
                                                                                    onClick={() => setfileUpload4checkbox(!fileUpload4checkbox)}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Proof of Income (example: Tax Return/SSI)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input {...register("income_proof")} type="file" disabled={cardtitle === "View Member" ? true : false} />
                                                                            </div>
                                                                        </div>
                                                                        {activeuser.income_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.income_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.income_proof)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Upload signed document in here</label>
                                                                    <div className="btn-group pt-1 col-md-12">

                                                                    <div
                                                                        className="drop-zone"
                                                                        onDragOver={handleDragOver}
                                                                        onDrop={handleDrop}
                                                                    >
                                                                        <input
                                                                            type="file"
                                                                            id="file-upload"
                                                                            onChange={handleFileInputChange}
                                                                            style={{ display: cardtitle === "View Member" ? "none" : "block" }}
                                                                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"  // Add accepted file types
                                                                            multiple={false}  // Single file upload
                                                                        />
                                                                        <label htmlFor="file-upload" className="upload-label">
                                                                            <i className="fa fa-upload"></i>
                                                                            <p>Click here or drag files to upload the signed document</p>
                                                                        </label>
                                                                        
                                                                        {selectedFiles.length > 0 && (
                                                                            <div className="selected-files">
                                                                                <ul>
                                                                                    {selectedFiles.map((file, index) => (
                                                                                        <li key={index}>
                                                                                            {file.name}
                                                                                            <span onClick={() => {
                                                                                                const newFiles = selectedFiles.filter((_, i) => i !== index);
                                                                                                setSelectedFiles(newFiles);
                                                                                            }}>
                                                                                                <i className="fa fa-times"></i>
                                                                                            </span>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                        {/* <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Signed Form</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input  {...register("signed_form")} type="file" disabled={cardtitle === "View Member" ? true : false} />
                                                                            </div>
                                                                        </div> */}
                                                                        {/* {activeuser.dl_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.dl_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.dl_proof)}</a>
                                                                        </div>
                                                                        } */}

                                                                    </div>
                                                                    <div>
                                                                    {activeuser.signed_form && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.signed_form)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.signed_form)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {/* <FileUpload></FileUpload> */}
                                                            </div>
                                                            <div className="row">
                                                            {cardtitle !== "View Member" && (
                                                                <>
                                                                {/* Left side buttons */}
                                                                <div className="col-md-6 d-flex justify-content-start gap-3">
                                                                    <button type="submit" className="btn btn-dark shadow ">
                                                                    Save
                                                                    </button>
                                                                    <button type="button" className="btn btn-info shadow " onClick={downloadFormValuesAsJson}>
                                                                    Download Form
                                                                    </button>
                                                                    <button type="button" className="btn btn-primary shadow " onClick={Print}>
                                                                    Print
                                                                    </button>
                                                                    <button type="button" className="btn btn-warning shadow " onClick={(e) => cardbtn(e)}>
                                                                    Back
                                                                    </button>
                                                                </div>

                                                                {/* Right side buttons */}
                                                                <div className="col-md-6 d-flex justify-content-end gap-3">
                                                                    <button type="button" className="btn btn-success shadow " onClick={(e) => updateMemberWFStatus('Approved')}>
                                                                    Approve
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger shadow " onClick={(e) => updateMemberWFStatus('Rejected')}>
                                                                    Reject
                                                                    </button>
                                                                </div>
                                                                </>
                                                            )}
                                                            </div>
                                                        </form>
                                                    </div>
                                                    :
                                                    <>
                                                    <div className="basic-form">
                                                       <div className="row">
                                                           <div className="form-group mb-3 col-md-4">
                                                               <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <br/>
                                                    <Table responsive className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>S NO.</strong>
                                                                </th>
                                                                <th>
                                                                <strong>FIRST NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>LAST NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>DATE OF BIRTH</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>HOME PHONE</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>ACTIONS</strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                                return [
                                                                    <tr key={i}>
                                                                        <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                                        <td>{item.first_name}</td>
                                                                        <td>{item.last_name}</td>
                                                                        <td>{item.dob}</td>
                                                                        <td>{item.home_phone}</td>
                                                                        <td><div className="d-flex">
                                                                            <Link
                                                                                onClick={(e) => viewuser(e, item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fa fa-eye"></i>
                                                                            </Link>
                                                                            <Link
                                                                                onClick={(e) => edituser(e, item)}
                                                                                className="btn btn-primary shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fa fa-pencil"></i>
                                                                            </Link>
                                                                            <Link
                                                                                onClick={(e) => deleteuser(e, item)}
                                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Link>
                                                                        </div></td>
                                                                    </tr>
                                                                ];
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                                   
                                                }
                                               
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Approved">
                                                {Showform === true
                                                    ?
                                                    <div className="basic-form">
                                                        <form>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-3">
                                                                    <label>Sponsor</label>
                                                                    <select
                                                                        {...registerForm1("funding_source")}
                                                                        defaultValue={"option"}
                                                                        id="SponsorinputState"
                                                                        className="form-control"
                                                                        value={activeuser?.['Sponsor'] || Sponsor}
                                                                        onChange={(e) => setSponsor(e.target.value)}
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                    >
                                                                        <option value="ARPA">ARPA</option>
                                                                        <option value="USDA">USDA</option>
                                                                    </select>
                                                                    {errors.funding_source && <small id="emailHelp" class="text-danger">{errors.funding_source?.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>First Name</label>
                                                                    <input
                                                                        {...registerForm1("first_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['FIRST NAME']}
                                                                    />
                                                                    {errors.first_name && <small id="emailHelp" class="text-danger">{errors.first_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Last Name</label>
                                                                    <input
                                                                        {...registerForm1("last_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['LAST NAME']}
                                                                    />
                                                                    {errors.last_name && <small id="emailHelp" class="text-danger">{errors.last_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Full Name</label>
                                                                    <input
                                                                        {...registerForm1("member_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['NAME']}
                                                                    />
                                                                    {errors.member_name && <small id="emailHelp" class="text-danger">{errors.member_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Date</label>
                                                                    <input
                                                                        {...registerForm1("dob")}
                                                                        type="date"
                                                                        className="form-control"
                                                                        placeholder="Date"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['DATE']}
                                                                    />
                                                                    {errors.dob && <small id="emailHelp" class="text-danger">{errors.dob?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Ethnicity</label>
                                                                    <select
                                                                        {...registerForm1("ethnicity")}
                                                                        defaultValue={"option"}
                                                                        id="EthnicityinputState"
                                                                        className="form-control"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ETHINICITY']}
                                                                    >
                                                                        <option value="option" disabled>
                                                                            Choose Ethnicity...
                                                                        </option>
                                                                        <option value="Black">Black</option>
                                                                        <option value="Caucasian">Caucasian</option>
                                                                        <option value="African American">African American</option>
                                                                        <option value="Native American">Native American</option>
                                                                        <option value="Asian">Asian</option>
                                                                        <option value="Alaskan Native">Alaskan Native</option>
                                                                        <option value="Hawaiian">Hawaiian</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                    {errors.ethnicity && <small id="emailHelp" class="text-danger">{errors.ethnicity?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Address</label>
                                                                    <input
                                                                        {...registerForm1("address")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Address"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ADDRESS']}
                                                                    />
                                                                    {errors.address && <small id="emailHelp" class="text-danger">{errors.address?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>City</label>
                                                                    <input
                                                                        {...registerForm1("city")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="City"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['CITY']}
                                                                    />
                                                                    {errors.city && <small id="emailHelp" class="text-danger">{errors.city?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>State</label>
                                                                    <input
                                                                        {...registerForm1("state")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="State"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['STATE']}
                                                                    />
                                                                    {errors.state && <small id="emailHelp" class="text-danger">{errors.state?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>UNZ</label>
                                                                    <input
                                                                        {...registerForm1("unz_id")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="UNZ"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['UNZ ID']}
                                                                    />
                                                                    {errors.unz_id && <small id="emailHelp" class="text-danger">{errors.unz_id?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Cost</label>
                                                                    <input
                                                                        {...registerForm1("projected_cost")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Cost"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PROJECTED COST']}
                                                                    />
                                                                    {errors.projected_cost && <small id="emailHelp" class="text-danger">{errors.projected_cost?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Site Id</label>
                                                                    <input
                                                                        {...registerForm1("site_id")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Site Id"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['SITE ID']}
                                                                    />
                                                                    {errors.site_id && <small id="emailHelp" class="text-danger">{errors.site_id?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Pin code</label>
                                                                    <input
                                                                        {...registerForm1("AL")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Zipcode"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ZIP CODE']}
                                                                    />
                                                                    {errors.AL && <small id="emailHelp" class="text-danger">{errors.AL?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Home Phone</label>
                                                                    <input
                                                                        {...registerForm1("home_phone")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Home"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PHONE - HOME']}
                                                                    />
                                                                    {errors.home_phone && <small id="emailHelp" class="text-danger">{errors.home_phone?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Mobile Phone</label>
                                                                    <input
                                                                        {...registerForm1("mobile_phone")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Cellular"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PHONE - CELLULAR']}
                                                                    />
                                                                    {errors.mobile_phone && <small id="emailHelp" class="text-danger">{errors.mobile_phone?.message}</small>}
                                                                </div>
                                                                <hr></hr>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-4">
                                                                        <label>Property Size</label>
                                                                        <input
                                                                            {...registerForm1("property_size")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Property Size"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['PROPERTY SIZE']}
                                                                        />
                                                                        {errors.property_size && <small id="emailHelp" class="text-danger">{errors.property_size?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label>Latitude  </label>
                                                                        <input
                                                                            {...registerForm1("latitude")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Latitude"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['LATIDUDE']}
                                                                        />
                                                                        {errors.latitude && <small id="emailHelp" class="text-danger">{errors.latitude?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label>Longitude </label>
                                                                        <input
                                                                            {...registerForm1("longitude")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Longitude"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['LONGITUDE']}
                                                                        />
                                                                        {errors.longitude && <small id="emailHelp" class="text-danger">{errors.longitude?.message}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-6" style={{ display: "flex" }}>
                                                                        <label>Do you live in an unincorporated area in Lowndes County?</label>
                                                                        <div className="btn-group makebtnleft pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("unicorporated_area")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox1"
                                                                                    value={"True"}
                                                                                    // onClick={() => checkboxFun("yes")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['UNICORP'] === "YES" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Yes</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("unicorporated_area")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox2"
                                                                                    value={"False"}
                                                                                    // onClick={() => checkboxFun("no")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['UNICORP'] === "NO" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >No</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.unicorporated_area && <small id="emailHelp" class="text-danger">{errors.unicorporated_area?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-6">
                                                                        <label>Type of residence</label>
                                                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("residence_type")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox3"
                                                                                    value={"House"}
                                                                                    // onClick={() => checkboxFun("House")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['TYPE OF RESIDENCE'] === "House" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox3"
                                                                                >House</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("residence_type")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox4"
                                                                                    value={"Mobile home"}
                                                                                    // onClick={() => checkboxFun("Mobile Home")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['TYPE OF RESIDENCE'] === "Mobile home" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox4"
                                                                                >Mobile Home</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.residence_type && <small id="emailHelp" class="text-danger">{errors.residence_type?.message}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-6">
                                                                        <label>Water Source</label>
                                                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("water_source")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox5"
                                                                                    value={"Public"}
                                                                                    // onClick={() => checkboxFun("Public")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['WATER SOURCE'] === "Public" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox5"
                                                                                >Public</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("water_source")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox6"
                                                                                    value={"Private"}
                                                                                    // onClick={() => checkboxFun("Private")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['WATER SOURCE'] === "Private" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox6"
                                                                                >Private (Well)</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.water_source && <small id="emailHelp" class="text-danger">{errors.water_source?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label># of Occupants  </label>
                                                                        <input
                                                                            {...registerForm1("no_of_occupants")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="# of Occupants  "
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['OCCUPANTS']}
                                                                        />
                                                                        {errors.no_of_occupants && <small id="emailHelp" class="text-danger">{errors.no_of_occupants?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label># of Bedrooms </label>
                                                                        <input
                                                                            {...registerForm1("no_of_bedrooms")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="# of Bedrooms "
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['BEDROOMS']}
                                                                        />
                                                                    </div>
                                                                    {errors.no_of_bedrooms && <small id="emailHelp" class="text-danger">{errors.no_of_bedrooms?.message}</small>}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>What is your current method of sewage disposal?</label>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...registerForm1("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox10"
                                                                                value={"Straight pipe or septic system failure affecting a neighboring property"}
                                                                                // onClick={() => checkboxFun("yes")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "1 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox10"
                                                                            >Straight pipe or septic system failure affecting a neighboring property.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...registerForm1("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox12"
                                                                                value={"Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc)"}
                                                                                // onClick={() => checkboxFun("no")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "2 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox12"
                                                                            >Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc.)</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...registerForm1("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox13"
                                                                                value={"Straight pipe or septic system failure confined to my property or inside of home"}
                                                                                // onClick={() => checkboxFun("no")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "3 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox13"
                                                                            >Straight pipe or septic system failure confined to my property or inside of home.</label>
                                                                        </div>
                                                                    </div>
                                                                    {errors.sewage_disp_meth && <small id="emailHelp" class="text-danger">{errors.sewage_disp_meth?.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Documents Needed</label>
                                                                    <div className="btn-group pt-1 col-md-12 d-flex">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">

                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Copy of Driver’s License/Non-Driver ID</label>
                                                                            </div>
                                                                        </div>

                                                                        {activeuser.dl_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.dl_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.dl_proof)}</a>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Proof of Land Ownership</label>
                                                                            </div>
                                                                        </div>
                                                                        {activeuser.land_own_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.land_own_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.land_own_proof)}</a>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Copy of Water Usage </label>
                                                                            </div>
                                                                        </div>

                                                                        {activeuser.water_usage_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.water_usage_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.water_usage_proof)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">

                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Proof of Income (example: Tax Return/SSI)</label>
                                                                            </div>
                                                                        </div>

                                                                        {activeuser.income_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.income_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.income_proof)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-3">
                                                                </div>
                                                                {cardtitle !== "View Member" &&
                                                                    <>
                                                                        {/* <div className="form-group mb-3 col-md-3">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save
                                                                    </button>
                                                                </div>
                                                                <div className="form-group mb-3 col-md-2">
                                                                    <button type="button" className="btn btn-info" onClick={(e) => updateMemberWFStatus('Approved')}>
                                                                        Approve
                                                                    </button>
                                                                </div>
                                                                <div className="form-group mb-3 col-md-2">
                                                                    <button type="button" className="btn btn-danger" onClick={(e) => updateMemberWFStatus('Rejected')}>
                                                                        Reject
                                                                    </button>
                                                                </div> */}

                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <button type="button" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }

                                                                <div className="form-group mb-3 col-md-3"></div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    :
                                                    <>
                                                    <div className="basic-form">
                                                       <div className="row">
                                                           <div className="form-group mb-3 col-md-4">
                                                               <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <br/>
                                                    <Table responsive className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>S NO.</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>FIRST NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>LAST NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>DATE OF BIRTH</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>HOME PHONE</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>ACTIONS</strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                                return [
                                                                    <tr key={i}>
                                                                         <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                                         <td>{item.first_name}</td>
                                                                        <td>{item.last_name}</td>
                                                                        <td>{item.dob}</td>
                                                                        <td>{item.home_phone}</td>
                                                                        <td><div className="d-flex">
                                                                            <Link
                                                                                onClick={(e) => viewuser(e, item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fa fa-eye"></i>
                                                                            </Link>
                                                                            {/* <Link
                                                                        onClick={(e) => edituser(e, item)}
                                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                                    >
                                                                        <i className="fa fa-pencil"></i>
                                                                    </Link> */}
                                                                            <Link
                                                                                onClick={(e) => deleteuser(e, item)}
                                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Link>
                                                                        </div></td>
                                                                    </tr>
                                                                ];
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Rejected">
                                                {Showform === true
                                                    ?
                                                    <div className="basic-form">
                                                        <form>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-3">
                                                                    <label>Sponsor</label>
                                                                    <select
                                                                        {...registerForm1("funding_source")}
                                                                        defaultValue={"option"}
                                                                        id="SponsorinputState"
                                                                        className="form-control"
                                                                        value={activeuser?.['Sponsor'] || Sponsor}
                                                                        onChange={(e) => setSponsor(e.target.value)}
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                    >
                                                                        <option value="ARPA">ARPA</option>
                                                                        <option value="USDA">USDA</option>
                                                                    </select>
                                                                    {errors.funding_source && <small id="emailHelp" class="text-danger">{errors.funding_source?.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>First Name</label>
                                                                    <input
                                                                        {...registerForm1("first_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['FIRST NAME']}
                                                                    />
                                                                    {errors.first_name && <small id="emailHelp" class="text-danger">{errors.first_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Last Name</label>
                                                                    <input
                                                                        {...registerForm1("last_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['LAST NAME']}
                                                                    />
                                                                    {errors.last_name && <small id="emailHelp" class="text-danger">{errors.last_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Full Name</label>
                                                                    <input
                                                                        {...registerForm1("member_name")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Name"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['NAME']}
                                                                    />
                                                                    {errors.member_name && <small id="emailHelp" class="text-danger">{errors.member_name?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Date</label>
                                                                    <input
                                                                        {...registerForm1("dob")}
                                                                        type="date"
                                                                        className="form-control"
                                                                        placeholder="Date"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['DATE']}
                                                                    />
                                                                    {errors.dob && <small id="emailHelp" class="text-danger">{errors.dob?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Ethnicity</label>
                                                                    <select
                                                                        {...registerForm1("ethnicity")}
                                                                        defaultValue={"option"}
                                                                        id="EthnicityinputState"
                                                                        className="form-control"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ETHINICITY']}
                                                                    >
                                                                        <option value="option" disabled>
                                                                            Choose Ethnicity...
                                                                        </option>
                                                                        <option value="Black">Black</option>
                                                                        <option value="Caucasian">Caucasian</option>
                                                                        <option value="African American">African American</option>
                                                                        <option value="Native American">Native American</option>
                                                                        <option value="Asian">Asian</option>
                                                                        <option value="Alaskan Native">Alaskan Native</option>
                                                                        <option value="Hawaiian">Hawaiian</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                    {errors.ethnicity && <small id="emailHelp" class="text-danger">{errors.ethnicity?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Address</label>
                                                                    <input
                                                                        {...registerForm1("address")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Address"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ADDRESS']}
                                                                    />
                                                                    {errors.address && <small id="emailHelp" class="text-danger">{errors.address?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>City</label>
                                                                    <input
                                                                        {...registerForm1("city")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="City"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['CITY']}
                                                                    />
                                                                    {errors.city && <small id="emailHelp" class="text-danger">{errors.city?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>State</label>
                                                                    <input
                                                                        {...registerForm1("state")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="State"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['STATE']}
                                                                    />
                                                                    {errors.state && <small id="emailHelp" class="text-danger">{errors.state?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>UNZ</label>
                                                                    <input
                                                                        {...registerForm1("unz_id")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="UNZ"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['UNZ ID']}
                                                                    />
                                                                    {errors.unz_id && <small id="emailHelp" class="text-danger">{errors.unz_id?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Cost</label>
                                                                    <input
                                                                        {...registerForm1("projected_cost")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Cost"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PROJECTED COST']}
                                                                    />
                                                                    {errors.projected_cost && <small id="emailHelp" class="text-danger">{errors.projected_cost?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Site Id</label>
                                                                    <input
                                                                        {...registerForm1("site_id")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Site Id"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['SITE ID']}
                                                                    />
                                                                    {errors.site_id && <small id="emailHelp" class="text-danger">{errors.site_id?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Pin code</label>
                                                                    <input
                                                                        {...registerForm1("AL")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Zipcode"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['ZIP CODE']}
                                                                    />
                                                                    {errors.AL && <small id="emailHelp" class="text-danger">{errors.AL?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Home Phone</label>
                                                                    <input
                                                                        {...registerForm1("home_phone")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Home"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PHONE - HOME']}
                                                                    />
                                                                    {errors.home_phone && <small id="emailHelp" class="text-danger">{errors.home_phone?.message}</small>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Mobile Phone</label>
                                                                    <input
                                                                        {...registerForm1("mobile_phone")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Cellular"
                                                                        disabled={cardtitle === "View Member" ? true : false}
                                                                        value={activeuser?.['PHONE - CELLULAR']}
                                                                    />
                                                                    {errors.mobile_phone && <small id="emailHelp" class="text-danger">{errors.mobile_phone?.message}</small>}
                                                                </div>
                                                                <hr></hr>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-4">
                                                                        <label>Property Size</label>
                                                                        <input
                                                                            {...registerForm1("property_size")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Property Size"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['PROPERTY SIZE']}
                                                                        />
                                                                        {errors.property_size && <small id="emailHelp" class="text-danger">{errors.property_size?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label>Latitude  </label>
                                                                        <input
                                                                            {...registerForm1("latitude")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Latitude"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['LATIDUDE']}
                                                                        />
                                                                        {errors.latitude && <small id="emailHelp" class="text-danger">{errors.latitude?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label>Longitude </label>
                                                                        <input
                                                                            {...registerForm1("longitude")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Longitude"
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['LONGITUDE']}
                                                                        />
                                                                        {errors.longitude && <small id="emailHelp" class="text-danger">{errors.longitude?.message}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-6" style={{ display: "flex" }}>
                                                                        <label>Do you live in an unincorporated area in Lowndes County?</label>
                                                                        <div className="btn-group makebtnleft pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("unicorporated_area")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox1"
                                                                                    value={"True"}
                                                                                    // onClick={() => checkboxFun("yes")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['UNICORP'] === "YES" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Yes</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("unicorporated_area")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox2"
                                                                                    value={"False"}
                                                                                    // onClick={() => checkboxFun("no")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['UNICORP'] === "NO" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >No</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.unicorporated_area && <small id="emailHelp" class="text-danger">{errors.unicorporated_area?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-6">
                                                                        <label>Type of residence</label>
                                                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("residence_type")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox3"
                                                                                    value={"House"}
                                                                                    // onClick={() => checkboxFun("House")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['TYPE OF RESIDENCE'] === "House" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox3"
                                                                                >House</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("residence_type")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox4"
                                                                                    value={"Mobile home"}
                                                                                    // onClick={() => checkboxFun("Mobile Home")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['TYPE OF RESIDENCE'] === "Mobile home" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox4"
                                                                                >Mobile Home</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.residence_type && <small id="emailHelp" class="text-danger">{errors.residence_type?.message}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group mb-3 col-md-6">
                                                                        <label>Water Source</label>
                                                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("water_source")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox5"
                                                                                    value={"Public"}
                                                                                    // onClick={() => checkboxFun("Public")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['WATER SOURCE'] === "Public" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox5"
                                                                                >Public</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="btn-group pt-1 col-md-3">
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    {...registerForm1("water_source")}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    id="checkbox6"
                                                                                    value={"Private"}
                                                                                    // onClick={() => checkboxFun("Private")}
                                                                                    disabled={cardtitle === "View Member" ? true : false}
                                                                                // checked={activeuser?.['WATER SOURCE'] === "Private" ? true : false}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox6"
                                                                                >Private (Well)</label>
                                                                            </div>
                                                                        </div>
                                                                        {errors.water_source && <small id="emailHelp" class="text-danger">{errors.water_source?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label># of Occupants  </label>
                                                                        <input
                                                                            {...registerForm1("no_of_occupants")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="# of Occupants  "
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['OCCUPANTS']}
                                                                        />
                                                                        {errors.no_of_occupants && <small id="emailHelp" class="text-danger">{errors.no_of_occupants?.message}</small>}
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label># of Bedrooms </label>
                                                                        <input
                                                                            {...registerForm1("no_of_bedrooms")}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="# of Bedrooms "
                                                                            disabled={cardtitle === "View Member" ? true : false}
                                                                            value={activeuser?.['BEDROOMS']}
                                                                        />
                                                                    </div>
                                                                    {errors.no_of_bedrooms && <small id="emailHelp" class="text-danger">{errors.no_of_bedrooms?.message}</small>}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>What is your current method of sewage disposal?</label>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...registerForm1("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox10"
                                                                                value={"Straight pipe or septic system failure affecting a neighboring property"}
                                                                                // onClick={() => checkboxFun("yes")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "1 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox10"
                                                                            >Straight pipe or septic system failure affecting a neighboring property.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...registerForm1("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox12"
                                                                                value={"Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc)"}
                                                                                // onClick={() => checkboxFun("no")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "2 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox12"
                                                                            >Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc.)</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                {...registerForm1("sewage_disp_meth")}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id="checkbox13"
                                                                                value={"Straight pipe or septic system failure confined to my property or inside of home"}
                                                                                // onClick={() => checkboxFun("no")}
                                                                                disabled={cardtitle === "View Member" ? true : false}
                                                                            // checked={activeuser?.['SEWAGE DISPOSAL'] === "3 option" ? true : false}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="checkbox13"
                                                                            >Straight pipe or septic system failure confined to my property or inside of home.</label>
                                                                        </div>
                                                                    </div>
                                                                    {errors.sewage_disp_meth && <small id="emailHelp" class="text-danger">{errors.sewage_disp_meth?.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Documents Needed</label>
                                                                    <div className="btn-group pt-1 col-md-12 d-flex">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">

                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox1"
                                                                                >Copy of Driver’s License/Non-Driver ID</label>
                                                                            </div>
                                                                        </div>

                                                                        {activeuser.dl_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.dl_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.dl_proof)}</a>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Proof of Land Ownership</label>
                                                                            </div>
                                                                        </div>
                                                                        {activeuser.land_own_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.land_own_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.land_own_proof)}</a>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Copy of Water Usage </label>
                                                                            </div>
                                                                        </div>

                                                                        {activeuser.water_usage_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.water_usage_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.water_usage_proof)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div className="btn-group pt-1 col-md-12">
                                                                        <div className="col-md-5 d-flex">
                                                                            <div className="form-check custom-checkbox">

                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="checkbox2"
                                                                                >Proof of Income (example: Tax Return/SSI)</label>
                                                                            </div>
                                                                        </div>

                                                                        {activeuser.income_proof && <div className="form-check custom-checkbox">
                                                                            <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.income_proof)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.income_proof)}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-3">
                                                                </div>
                                                                {cardtitle !== "View Member" &&
                                                                    <>
                                                                        {/* <div className="form-group mb-3 col-md-3">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save
                                                                    </button>
                                                                </div>
                                                                <div className="form-group mb-3 col-md-2">
                                                                    <button type="button" className="btn btn-info" onClick={(e) => updateMemberWFStatus('Approved')}>
                                                                        Approve
                                                                    </button>
                                                                </div>
                                                                <div className="form-group mb-3 col-md-2">
                                                                    <button type="button" className="btn btn-danger" onClick={(e) => updateMemberWFStatus('Rejected')}>
                                                                        Reject
                                                                    </button>
                                                                </div> */}
                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <button type="button" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }

                                                                <div className="form-group mb-3 col-md-3"></div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    :
                                                    <>
                     <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <br/>
                                                    <Table responsive className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>S NO.</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>FIRST NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>LAST NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>DATE OF BIRTH</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>HOME PHONE</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>ACTIONS</strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                                return [
                                                                    <tr key={i}>
                                                                         <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                                        <td>{item.first_name}</td>
                                                                        <td>{item.last_name}</td>
                                                                        <td>{item.dob}</td>
                                                                        <td>{item.home_phone}</td>
                                                                        <td><div className="d-flex">
                                                                            <Link
                                                                                onClick={(e) => viewuser(e, item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fa fa-eye"></i>
                                                                            </Link>
                                                                            {/* <Link
                                                                        onClick={(e) => edituser(e, item)}
                                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                                    >
                                                                        <i className="fa fa-pencil"></i>
                                                                    </Link> */}
                                                                            <Link
                                                                                onClick={(e) => deleteuser(e, item)}
                                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Link>
                                                                        </div></td>
                                                                    </tr>
                                                                ];
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                                }
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </Fragment >
    )
}
