import React, { Fragment, useState, useEffect } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
    Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import swal from "sweetalert";
import Loader from "../../layouts/nav/Loader.js";
import CommonPrint from "./CommonPrint.js";
import WorkflowHeader from "./WorkflowHeader.js";
const schema = yup.object().shape({
    first_name: yup.mixed(),
    last_name: yup.mixed(),
    address: yup.mixed(),
    city: yup.mixed(),
    state: yup.mixed(),
    AL: yup.mixed(),
    mobile_phone: yup.mixed(),
    contractor: yup.string().nullable().required('Contractor assignment is required'),
    assigned_date: yup.string().nullable().required('Assigned date is required'),
    cost: yup.string().nullable().required('Cost is required'),
    document: yup.mixed(),
    comments: yup.string().nullable(),
    status: yup.string()
});

const Soiltest = ({ activeTab }) => {
    const [btntitle, setbtntitle] = useState("Add Soil Test");
    const [cardtitle, setcardtitle] = useState("List of Soil Test");
    const [Showform, setShowform] = useState(false);
    const [soilttesttable, setsoilttesttable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [memberId, setMemberId] = useState("");
    const [usertable, setusertable] = useState([]);
    const [applicationTab, setApplicationTab] = useState("Pending");
    const [ShowApprove, setShowApprove] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    function checkboxFun(option) {
        console.log(option)
    }
    useEffect(() => {
        if (activeTab == "soiltest") {
            setbtntitle("Add Soil Test");
            setcardtitle("List of Soil Test")
            setactiveuser({})
            setShowform(false)
            getWFSoilList('Pending');
            getMembers()
            setApplicationTab('Pending')
        }

    }, [activeTab])

    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) 
               //  user.assigned_date.toLowerCase().includes(searchInput.toLowerCase()) ||
               //  user.cost.includes(searchInput.toLowerCase())
        );
    };

    const calculatePaginationDetails = (page, data, currPage) => {
        if (typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase())  
              //  user.assigned_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                //user.cost.includes(searchInput.toLowerCase())
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if (page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
            currentPage = currentPage - 1;
            if (currentPage < 1) {
                currentPage = 1
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
            currentPage = currentPage + 1;
            if (currentPage > pages) {
                currentPage = pages;
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
            currentPage = 1
        } else if (page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
            currentPage = pages;
        } else {
            currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for (let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
            if (i <= pages)
                pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize);
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for (let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
            usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)
        let updatedState = {
            usersToBeShown: usersToBeShown,
            pageArray: pageArray,
            firstPage: firstPage,
            lastPage: lastPage,
            currentPage: currentPage,
            sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
    }

    const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
        calculatePaginationDetails(txt !== undefined ? txt : e.target.text, soilttesttable, currentPage);
        // }
    }

    const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
        //calculatePaginationDetails(1, soilttesttable, currentPage)
        setTimeout(() => {
            if (document.querySelector(".page-link"))
                document.querySelector(".page-link").click()
        }, 1000);
    }
    useEffect(() => {
        calculatePaginationDetails(1, soilttesttable, currentPage)
     }, [searchInput, soilttesttable]);

    const getMembers = () => {
        apiServices.getContractorList().then(response => {
            setusertable(response.data.filter((item) => item.scope == 'Soil Test'))
        }).catch(error => {

        })
    }
    const getWFSoilList = (sType) => {
        setIsLoading(true);
        apiServices.getWFSoilList(sType).then(response => {
            setIsLoading(false);
            setsoilttesttable(response.data)
            calculatePaginationDetails(1, response.data, currentPage)
        }).catch(error => {

        })
    }
    function cardbtn() {
        if (btntitle === 'Add Soil Test' || btntitle === 'Edit Soil Test' || btntitle === 'View Soil Test') {
            setbtntitle("Back");
            setcardtitle("Add Soil Test")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Soil Test");
            setcardtitle("List of Soil Test")
            setactiveuser({})
            setShowform(false)
        }
    }

    function viewuser(e, user) {
        e.preventDefault()
        setMemberId(user.member_id)
        setShowApprove(true);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('contractor', user.contractor_id);
            setValue('assigned_date', user.assigned_date);
            setValue('comments', user.comments);
            setValue('cost', user.cost);
            if (user.document != '' && user.document != undefined)
                setShowApprove(false);
            for (const key in response) {
                //setValue(key, value[key]);
            }
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("View Soil Test")
        setactiveuser(user)
        setShowform(true)
    }
    function GetFileName(file_id) {
        const parts = file_id.split('/');
        return parts[parts.length - 1];
    }
    const [imageData, setImageData] = useState(null);

    //   const fetchImageData = async () => {
    //     try {
    //       const response = await fetch('https://images.unsplash.com/photo-1605559424843-9e4c228bf1c2?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGNhcnN8ZW58MHx8MHx8fDA%3D');
    //       const blob = await response.blob();
    //       setImageData(blob);
    //       handleDownloadClick();
    //     } catch (error) {
    //       console.error('Error fetching image data:', error);
    //     }
    //   };

    //   const handleDownloadClick = () => {
    //     if (imageData) {
    //       const url = URL.createObjectURL(imageData);
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.download = 'image.jpg'; // Set the desired file name here
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       console.error('No image data available to download');
    //     }
    //   };
    function downloadDocument(e, file_id) {
        e.preventDefault()
        apiServices.DownloadDocument(file_id).then(response => {
            const parts = file_id.split('/');
            //const nblob = response.blob();
            const lastPart = parts[parts.length - 1];
            //const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = lastPart// encodeURIComponent(lastPart); // Encode the file name
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Clean up the URL
        }).catch(error => {

        })
    }
    const onSubmit = (e) => {
        setIsLoading(true);
        apiServices.updateWFSoil(getValues(), activeuser.id).then(response => {
            // reset()
            setIsLoading(false);
            notifySuccess(response.data[0].message);
            cardbtn()
            getWFSoilList('Pending');
        }).catch(error => {
            setIsLoading(false);

        })

    };
    const updateWFStatus = (sType) => {
        var message;
        if (sType == "Approved") {
            message = "Approve";
        } else {
            message = "Reject";
        }
        swal({
            title: message,
            text:
                "Are you sure you want to " + message + "?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setIsLoading(true);
                apiServices.updateWFStatus(sType, memberId, 'soil_test').then(response => {
                    setIsLoading(false);
                    notifySuccess(response.data[0].message);
                    cardbtn()
                    getWFSoilList('Pending');
                }).catch(error => {
                    setIsLoading(false);
                });
            } else {
                //   swal("Your imaginary file is safe!");
            }
        })


    };
    function edituser(e, user) {
        e.preventDefault();
        setMemberId(user.member_id)
        setShowApprove(true);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('contractor', user.contractor_id);
            setValue('assigned_date', user.assigned_date);
            setValue('comments', user.comments);
            setValue('cost', user.cost);
            if (user.document != '' && user.document != undefined)
                setShowApprove(false);
            for (const key in response) {
                //setValue(key, value[key]);
            }
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("Edit Soil Test")
        setactiveuser(user)
        setShowform(true)
    }
    function Print(elem) {
        var mywindow = window.open('Iwashwell', 'PRINT', 'height=400,width=600');

        // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title  + '</h1>');

        mywindow.document.head.innerHTML += '<link rel="stylesheet" type="text/css" href="./css/style.css">';

        mywindow.document.write(document.getElementById('printableSoildiv').innerHTML);
        // mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    }
    function deleteuser(e, user, sType) {
        e.preventDefault()
        // setactiveuser(user)
        // notifyError();
        apiServices.deleteWFSoil(user.id).then(response => {
            cardbtn()
            getWFSoilList(sType);
            notifyError();
            setbtntitle("Add Soil Test");
            setcardtitle("List of Soil Test")
            setactiveuser({})
            setShowform(false)
        }).catch(error => {
        });
    }
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifyError = () => {
        toast.error("Soil Test Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <div>
                        <div style={{ display: 'none' }} className="pdf-page" id="printableSoildiv">
                            <div className="basic-form">
                                <CommonPrint></CommonPrint>
                                <p>Header</p>
                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', width: '200PX' }}>First Name:</td>
                                            <td>{activeuser.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Last Name:</td>
                                            <td>{activeuser.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Address:</td>
                                            <td>{activeuser.address}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Phone:</td>
                                            <td>{activeuser.mobile_phone}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>State:</td>
                                            <td>{activeuser.state}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>City:</td>
                                            <td>{activeuser.city}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Zip Code:</td>
                                            <td>{activeuser.AL}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr></hr>

                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', width: '200PX' }}>Assigned Date:</td>
                                            <td>{activeuser.assigned_date}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Site Id:</td>
                                            <td>{activeuser.site_id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Cost:</td>
                                            <td>{activeuser.cost}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Comments:</td>
                                            <td>{activeuser.comments}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Upload Documents:</td>
                                            <td>{activeuser.document}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr></hr>

                            </div>
                        </div>
                    </div>
                    <Tab.Container defaultActiveKey="Pending" activeKey={applicationTab}>
                        <Nav as="ul" className="nav-tabs">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="Pending" disabled={applicationTab == 'Pending' ? true : false} onClick={() => { getWFSoilList('Pending'); setApplicationTab('Pending');SetSearchInput("");  }}>
                                    <i className={`flaticon-381-windows me-2`} />
                                    Pending
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="Rejected" disabled={applicationTab == 'Rejected' ? true : false} onClick={() => { getWFSoilList('Rejected'); setApplicationTab('Rejected');SetSearchInput("");  }}>
                                    <i className={`flaticon-381-windows me-2`} />
                                    Rejected
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-4">
                            <Tab.Pane eventKey="Pending">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <h4 className="card-title">{cardtitle}</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 text-end">
                                        {/* <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                    {btntitle}
                </button> */}
                                    </div>
                                </div>
                                <div className="row">
                                    {Showform === true
                                        ?
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <WorkflowHeader activeMember={activeuser.member_id}></WorkflowHeader>
                                            <br></br>
                                            <div className="row">
                                                {/* <div className="form-group mb-3 col-md-4">
                                                    <label>First Name</label>
                                                    <input
                                                        {...register("first_name")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        disabled={true}
                                                        value={activeuser['FIRST NAME']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Last Name</label>
                                                    <input
                                                        {...register("last_name")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        disabled={true}
                                                        value={activeuser['LAST NAME']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Address</label>
                                                    <input
                                                        {...register("address")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Address"
                                                        disabled={true}
                                                        value={activeuser['ADDRESS']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Phone</label>
                                                    <input
                                                        {...register("mobile_phone")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Phone"
                                                        disabled={true}
                                                        value={activeuser['PHONE NO']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>State</label>
                                                    <input
                                                        {...register("state")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="State"
                                                        disabled={true}
                                                        value={activeuser['STATE']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>City</label>
                                                    <input
                                                        {...register("city")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="City"
                                                        disabled={true}
                                                        value={activeuser['CITY']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Zipcode</label>
                                                    <input
                                                        {...register("AL")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Zipcode"
                                                        disabled={true}
                                                        value={activeuser['ZIP CODE']}
                                                    />
                                                </div> */}
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Assigned To</label>
                                                    {/* <select
                                                {...register("contractor")}
                                                //defaultValue={"option"}
                                                id="SponsorinputState"
                                                className="form-control"
                                                value={activeuser['PROFESSIONAL NAME'] || ""}
                                                disabled={cardtitle === "View Soil Test" ? true : false}
                                            >
                                                
                                                {usertable.map((item, i) => {
                                                    <option key={item.id} value={item.id}>{item.member_name}</option>
                                                })}
                                            </select> */}
                                                    <select
                                                        {...register("contractor")}
                                                        id="SponsorinputState"
                                                        className="form-control"
                                                        //value={activeuser['CONTRACTOR'] || ""}
                                                        disabled={cardtitle === "View Soil Test" ? true : false}
                                                    >
                                                        {usertable.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    {errors.contractor && <small id="emailHelp" class="text-danger">{errors.contractor?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Assigned Date</label>
                                                    <input
                                                        {...register("assigned_date")}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Assign Date"
                                                        // min={currentDate}
                                                        disabled={cardtitle === "View Soil Test" ? true : false}
                                                        value={activeuser['ASSIGNED DATE']}
                                                    />
                                                    {errors.assigned_date && <small id="emailHelp" class="text-danger">{errors.assigned_date?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Cost</label>
                                                    <input
                                                        {...register("cost")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="cost"
                                                        disabled={cardtitle === "View Soil Test" ? true : false}
                                                        value={activeuser['COST']}
                                                    />
                                                    {errors.cost && <small id="emailHelp" class="text-danger">{errors.cost?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Upload Documents</label>
                                                    <input {...register("document")} type="file" className="makebtnleft" disabled={cardtitle === "View Soil Test" ? true : false} />
                                                </div>

                                                {activeuser.document && <div className="form-group mb-3 col-md-4">
                                                    {/* <Link
                                                        onClick={(e) => downloadDocument(e,activeuser.document)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i> {activeuser.document}
                                                    </Link> */}
                                                    <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.document)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.document)}</a>

                                                </div>}
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Comments</label>
                                                    <textarea
                                                        {...register("comments")}
                                                        rows="10"
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        disabled={cardtitle === "View Soil Test" ? true : false}
                                                        value={activeuser['COMMENTS']} />
                                                    {errors.comments && <small id="emailHelp" class="text-danger">{errors.comments?.message}</small>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* <div className="form-group mb-3 col-md-3">
                        </div> */}
                                                {/* {cardtitle === "View Soil Test" ? */}
                                                    {/* <> */}
                                                    {/* </> : */}
                                                     <>
                                                        <div className={`${cardtitle === "View Soil Test" ? "soil" : "col-md-15"} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Soil Test" ? "block" : ""}}>
                                                            <button type="submit" className="btn btn-dark" style={{display: cardtitle === "View Soil Test" ? "none" : ""}}>
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className={`${cardtitle === "View Soil Test" ? "soil" : "col-md-15"} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Soil Test" ? "block" : ""}}>
                                                            <button disabled={ShowApprove} type="button" className="btn btn-secondary" onClick={(e) => updateWFStatus('Approved')} style={{display: cardtitle === "View Soil Test" ? "none" : ""}}>
                                                                Approve
                                                            </button>
                                                        </div>
                                                        <div className={`${cardtitle === "View Soil Test" ? "soil" : "col-md-15"} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Soil Test" ? "block" : ""}}>
                                                            <button type="button" disabled={cardtitle === "View Soil Test" ? true : false} className="btn btn-danger" onClick={(e) => updateWFStatus('Rejected')} style={{display: cardtitle === "View Soil Test" ? "none" : ""}}>
                                                                Reject
                                                            </button>
                                                        </div>
                                                        <div className={`${cardtitle === "View Soil Test" ? "soil" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Soil Test" ? "block" : "none"}}>
                                                            
                                                        </div></>
                                                        {/* } */}
                                                <div className={`${cardtitle === "View Soil Test" ? "col-md-2" : "col-md-15"} form-group mb-3 text-center`}>
                                                    <button type="button" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                        Back
                                                    </button>
                                                </div>
                                                <div className={`${cardtitle === "View Soil Test" ? "col-md-2" : "col-md-15 text-center"} form-group mb-3 text-center`}>
                                                    <button type="submit" className="btn btn-primary" onClick={Print}>
                                                        Print
                                                    </button>
                                                </div>
                                                {/* <div className="form-group mb-3 col-md-3"></div> */}
                                            </div>
                                        </form>

                                        :
                                        <>
                                            <div className="basic-form">
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-4">
                                                        <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <Table responsive className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>S NO.</strong>
                                                        </th>
                                                        <th>
                                                            <strong>FIRST NAME</strong>
                                                        </th>
                                                        <th>
                                                            <strong>LAST NAME</strong>
                                                        </th>
                                                        <th>
                                                            <strong>ASSIGNED DATE</strong>
                                                        </th>
                                                        <th>
                                                            <strong>COST</strong>
                                                        </th>
                                                        <th>
                                                            <strong>FLAG</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {FilteredData().map((item, i) => {
                                                        console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize));
                                                        return [
                                                            <tr key={i}>
                                                                <td>{(parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + 1 + i}</td>
                                                                <td>{item.first_name}</td>
                                                                <td>{item.last_name}</td>
                                                                <td>{item.assigned_date}</td>
                                                                <td>{item.cost}</td>
                                                                <td><i class="fa fa-flag fa-2x" style={{ color: item.flag }}></i></td>
                                                                <td><div className="d-flex">
                                                                    <Link
                                                                        onClick={(e) => viewuser(e, item)}
                                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                                    >
                                                                        <i className="fa fa-eye"></i>
                                                                    </Link>
                                                                    <Link
                                                                        onClick={(e) => edituser(e, item)}
                                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                                    >
                                                                        <i className="fa fa-pencil"></i>
                                                                    </Link>
                                                                    <Link
                                                                        onClick={(e) => deleteuser(e, item, 'Pending')}
                                                                        className="btn btn-danger shadow btn-xs sharp"
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </Link>
                                                                </div></td>
                                                            </tr>
                                                        ];
                                                    })}
                                                </tbody>
                                            </Table>
                                            <br />
                                            {FilteredData().length > 0 &&
                                                <Pagination>
                                                    <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                                                    <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                                                        pageArray && pageArray.length &&
                                                        pageArray.map(
                                                            (item) => (
                                                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                                                    active={currentPage == item}>{item}</Pagination.Item>
                                                            )
                                                        )
                                                    }
                                                    <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                                                    <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                                                </Pagination>
                                            }
                                        </>
                                    }
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Rejected">
                                <div className="row">
                                <>
                     <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <br/>
                    <Table responsive className="table table-striped">
                                  
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>S NO.</strong>
                                                </th>
                                                <th>
                                                    <strong>FIRST NAME</strong>
                                                </th>
                                                <th>
                                                    <strong>LAST NAME</strong>
                                                </th>
                                                <th>
                                                    <strong>ASSIGNED DATE</strong>
                                                </th>
                                                <th>
                                                    <strong>COST</strong>
                                                </th>
                                                <th>
                                                    <strong>FLAG</strong>
                                                </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                return [
                                                    <tr key={i}>
                                                         <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                        <td>{item.first_name}</td>
                                                        <td>{item.last_name}</td>
                                                        <td>{item.assigned_date}</td>
                                                        <td>{item.cost}</td>
                                                        <td><i class="fa fa-flag fa-2x" style={{ color: item.flag }}></i></td>
                                                        <td><div className="d-flex">
                                                            <Link
                                                                onClick={(e) => deleteuser(e, item, 'Rejected')}
                                                                className="btn btn-danger shadow btn-xs sharp"
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </Link>
                                                        </div></td>
                                                    </tr>
                                                ];
                                            })}
                                        </tbody>
                                    </Table>
                                    <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </>
            }
        </>
    )
};
const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};
export default Soiltest;
